import { createSlice } from '@reduxjs/toolkit';
// utils
import { ICouponState } from '../../@types/coupon';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState: ICouponState = {
  isLoading: false,
  error: null,
  coupons: [],
  coupon: null,
  couponsStats: null,
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUPONS
    getCouponsSuccess(state, action) {
      state.isLoading = false;
      state.coupons = action.payload;
    },

    // CREATE COUPON
    createCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupons = [...state.coupons, action.payload];
    },

    // UPDATE COUPON
    updateCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupons = state.coupons.filter((coupon) => coupon.id !== action.payload.id);
      state.coupons = [...state.coupons, action.payload];
    },

    // DELETE COUPON
    deleteCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupons = state.coupons.filter((coupon) => coupon.id !== action.payload.id);
    },

    // GET COUPON
    getCouponSuccess(state, action) {
      state.isLoading = false;
      state.coupon = action.payload;
    },

    // GET COUPONS STATS
    getCouponsStatsSuccess(state, action) {
      state.isLoading = false;
      state.couponsStats = action.payload;
    },
  },
});

// Reducer

export default slice.reducer;

// Actions

export const {
  startLoading,
  stopLoading,
  hasError,
  getCouponsSuccess,
  createCouponSuccess,
  updateCouponSuccess,
  deleteCouponSuccess,
  getCouponSuccess,
  getCouponsStatsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
export function getCoupons() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/coupons');
      dispatch(getCouponsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCouponsStats() {
  return async (dispatch: any) => {
    try {
      const response = await axios.get('/coupons/stats');
      console.log(response.data);
      const elraghy = response.data.find((item: any) => item.source === 'ELRAGHY');
      const qtaf = response.data.find((item: any) => item.source === 'QTAF');
      const foundationDay = response.data.find((item: any) => item.source === 'FOUNDATION_DAY');
      const couponsStats = {
        elraghy: {
          totalCoupons: elraghy.totalCoupons,
          totalUseCount: elraghy.totalUseCount,
        },
        qtaf: {
          totalCoupons: qtaf.totalCoupons,
          totalUseCount: qtaf.totalUseCount,
        },
        foundationDay: {
          totalCoupons: foundationDay.totalCoupons,
          totalUseCount: foundationDay.totalUseCount,
        },
      };
      dispatch(getCouponsStatsSuccess(couponsStats));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createCoupon(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/coupons', data);
      dispatch(createCouponSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updateCoupon(id: number, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/coupons/${data.id}`, data);
      dispatch(updateCouponSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteCoupon(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.delete(`/coupons/${id}`);
      dispatch(deleteCouponSuccess({ id }));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
