// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        roles: ['SUDO', 'ADMIN', 'MODERATOR'],
      },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },
  {
    subheader: 'event',
    items: [
      {
        title: 'event',
        path: PATH_DASHBOARD.event.root,
        icon: ICONS.calendar,
        roles: ['SUDO', 'ADMIN', 'MODERATOR'],
      },
      {
        title: 'تحلیل',
        path: PATH_DASHBOARD.mange.root,
        icon: ICONS.calendar,
        roles: ['SUDO', 'ANALYST', 'MODERATOR'],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: ['SUDO', 'ADMIN'],
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list, roles: ['SUDO', 'ADMIN'] },
          // { title: 'create', path: PATH_DASHBOARD.user.new },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },

      // SERVICE
      {
        title: 'service',
        path: PATH_DASHBOARD.service.root,
        icon: ICONS.invoice,
        roles: ['SUDO', 'ADMIN'],

        children: [{ title: 'list', path: PATH_DASHBOARD.service.list, roles: ['SUDO', 'ADMIN'] }],
      },
      {
        title: 'partner',
        path: PATH_DASHBOARD.partner.root,
        icon: ICONS.external,
        roles: ['SUDO', 'ADMIN'],

        children: [{ title: 'list', path: PATH_DASHBOARD.partner.list, roles: ['SUDO', 'ADMIN'] }],
      },
      {
        title: 'brand',
        path: PATH_DASHBOARD.brand.root,
        icon: ICONS.banking,
        roles: ['SUDO', 'ADMIN'],

        children: [{ title: 'list', path: PATH_DASHBOARD.brand.list, roles: ['SUDO', 'ADMIN'] }],
      },
      {
        title: 'message',
        path: PATH_DASHBOARD.message.root,
        icon: ICONS.chat,
        roles: ['SUDO', 'ADMIN', 'MODERATOR', 'CUSTOMER_SERVICE'],

        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.message.list,
            roles: ['SUDO', 'ADMIN', 'MODERATOR', 'CUSTOMER_SERVICE'],
          },
        ],
      },
      // // BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        roles: ['SUDO', 'ADMIN', 'EDITOR'],

        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts, roles: ['SUDO', 'ADMIN', 'EDITOR'] },
        ],
      },
      // PERFORMANCE
      {
        title: 'العروض',
        path: PATH_DASHBOARD.performance.root,
        icon: ICONS.kanban,
        roles: ['SUDO', 'ADMIN'],

        children: [
          { title: 'list', path: PATH_DASHBOARD.performance.list, roles: ['SUDO', 'ADMIN'] },
        ],
      },
      // coupon
      {
        title: 'coupon',
        path: PATH_DASHBOARD.coupon.root,
        icon: ICONS.cart,
        roles: ['SUDO', 'ADMIN'],

        children: [
          { title: 'list', path: PATH_DASHBOARD.coupon.list, roles: ['SUDO', 'ADMIN'] },
          {
            title: 'analytics',
            path: PATH_DASHBOARD.coupon.stats,
            roles: ['SUDO', 'ADMIN', 'ANALYST'],
          },
        ],
      },
      // {
      //   title: 'neighborhood',
      //   path: PATH_DASHBOARD.neighborhood.root,
      //   icon: ICONS.external,
      //   roles: ['SUDO', 'ADMIN'],
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.neighborhood.list, roles: ['SUDO', 'ADMIN'] },
      //   ],
      // },
      // {
      //   title: 'hdyku',
      //   path: PATH_DASHBOARD.hdyku.root,
      //   icon: ICONS.file,
      //   roles: ['SUDO', 'ADMIN'],

      //   children: [{ title: 'list', path: PATH_DASHBOARD.hdyku.list, roles: ['SUDO', 'ADMIN'] }],
      // },
      {
        title: 'contact_us',
        path: PATH_DASHBOARD.contactInfo.root,
        icon: ICONS.label,
        roles: ['SUDO', 'ADMIN'],

        children: [
          { title: 'list', path: PATH_DASHBOARD.contactInfo.edit, roles: ['SUDO', 'ADMIN'] },
        ],
      },
      {
        title: 'الشروط و الأحكام',
        path: PATH_DASHBOARD.policy.root,
        icon: ICONS.file,
        roles: ['SUDO', 'ADMIN'],

        children: [{ title: 'list', path: PATH_DASHBOARD.policy.list, roles: ['SUDO', 'ADMIN'] }],
      },
    ],
  },
];

export default navConfig;
